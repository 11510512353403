import React from "react";
import Styles from './footer.module.css'
import './footerFlower.css';
import { FaFacebook, FaVine } from 'react-icons/fa';
import Grid from '@material-ui/core/Grid';
import 'typeface-roboto';



export default function footer() {  
	return (
	<nav className={Styles.shed} role="footer">
		<hr className="hr-repeat"></hr>		
		<Grid  container spacing={4}>
			<Grid className={Styles.footerPanelLeft} item xs={4}>			
				<a href="https://www.facebook.com/lea.luiten" target="_blank" rel="noopener noreferrer" title="facebook">					
					<FaFacebook />
					facebook
				</a>			
			</Grid>
			<Grid className={Styles.footerPanelCenter} item xs={4}>
				<a href="https://www.werkersindewijngaard.nl/team/lea-van-de-riet-luiten/" target="_blank" rel="noopener noreferrer" title="werkers in de wijngaard">				
					<FaVine />
					Werkers in de Wijngaard
				</a>				
			</Grid>
			<Grid className={Styles.footerPanelRight} item xs={4}>
				<img className={Styles.footerImage} alt="anbi" src={require('../images/anbi.png')} />
			</Grid>			
		</Grid>		
	</nav>
	);
  }