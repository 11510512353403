import React from "react";
import Styles from './logo.module.css'

export default ({ author }) => (
<div>
    <ul>
        <li className={Styles.logo}>
            <div className={Styles.logo_words}></div>
        </li>
        <li className={Styles.logo}>
            <div className={Styles.logo_pic}></div>
        </li>
    </ul>      
    <p className={Styles.slogan}>
        "{author.node.headline}"
    </p>
</div>
)