import React from 'react'
import Link from 'gatsby-link'
import styles from './navigation.module.css'
import '../components/navBirds.css';
import '../components/navClouds.css';


export default () => (
  <nav role="navigation">
    <div className="container">
	
    <ul>    
      <li className={styles.navigationItem}>                                        
        <Link to="/">
        <div className="cloudHome">        
          <div className="cloudHomeshadow">          
          </div>
        </div>        
        </Link>        
      </li>      
      <li className={styles.navigationItem}>                                        
        <Link to="/lea/">
        <div className="cloudLea">        
          <div className="cloudLeashadow">          
          </div>
        </div>        
        </Link>        
      </li>      
      <li className={styles.navigationItem}>                                        
        <Link to="/ZuidAfrika/">
        <div className="cloudBijbelclubs">        
          <div className="cloudBijbelclubsshadow">          
          </div>
        </div>        
        </Link>        
      </li>      
      <li className={styles.navigationItem}>                                        
        <Link to="/nieuwsbrief/">
        <div className="cloudNieuwsbrief">        
          <div className="cloudNieuwsbriefshadow">          
          </div>
        </div>        
        </Link>        
      </li>      
      <li className={styles.navigationItem}>                                        
        <Link to="/gift/">
        <div className="cloudOndersteun">        
          <div className="cloudOndersteunshadow">          
          </div>
        </div>        
        </Link>        
      </li>      
      <li className={styles.navigationItem}>                                        
        <Link to="/thuisfront/">
        <div className="cloudHometeam">        
          <div className="cloudHometeamshadow">          
          </div>
        </div>        
        </Link>        
      </li>
    </ul>      
    
    <div className="bird-container bird-container--one">
      <div className="bird bird--one"></div>
    </div>
    
    <div className="bird-container bird-container--two">
      <div className="bird bird--two"></div>
    </div>
    
    <div className="bird-container bird-container--three">
      <div className="bird bird--three"></div>
    </div>
    
    <div className="bird-container bird-container--four">
      <div className="bird bird--four"></div>
    </div>
    
  </div>    
  </nav>
)
